import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BreadcrumbMenu from '../../components/menus/bcmenu';
import InlineBodyTransform from '../../components/inlinebodytransform';
import Layout from '../../components/layout';
import React from 'react';
import SEO from '../../components/seo';
import SideNavBar from '../../components/menus/navbarmenu';
import { graphql } from 'gatsby';

let gmdv = require(`../../ghc_config`);

const Detail = ({ data, location }) => {

  //  Menu relation
  let menuName = gmdv.getMenuid(location)

  //  Page
  const post = data.nodePageWithAccordions
  let backgroundImage = ''
  if (post.relationships.field_image != null) {
    backgroundImage = post.relationships.field_image.localFile.childImageSharp.banner.src
    backgroundImage = encodeURI(backgroundImage)
  }

  return (
    <Layout>
      <SEO 
        title={post.title}
        description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
        keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="col-12 leftside">
              <SideNavBar menuName={menuName} location={location}/>
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight">
              <h1 className="pagetitle" 
                style={{backgroundImage: `url(${backgroundImage})`, backgroundSize: "300px"}}>
                {post.title}
              </h1>
              <BreadcrumbMenu menuName={menuName} location={location}/>
              <InlineBodyTransform bodyValue={post.body} />
              {post.relationships.field_accordions &&
              <Accordion id="accpages" className="lightgray-bk-Genesis py-1 rounded" allowZeroExpanded={true} preExpanded={["acc-0"]}>
              {post.relationships.field_accordions.map((Item, key) => {
                  return(
                    <AccordionItem key={key} uuid={`acc-${key}`}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span>{Item.field_accordion_item_title}</span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <InlineBodyTransform bodyValue={Item.field_accordion_item_body} />
                      </AccordionItemPanel>
                    </AccordionItem>
                  )
                })         
              }</Accordion>}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
  query($id: String!) {
    nodePageWithAccordions(id: { eq: $id }) {
      title
      body {
        value
      }
      created
      relationships {
        field_image {
          localFile{
            childImageSharp {
              banner: resize(width: 300) {
                src
              }
            }
          }
        }
        field_accordions {
          field_accordion_item_title
          field_accordion_item_body {
            value
          }
        }
      }
    }

    # Menu relation
    allMenuLinkContentMenuLinkContent {
      edges {
        node {
          title
          link {
            uri
          }
          menu_name
        }
      }
    }  
  }`;